//This uses the card template to create the two cards that will be rendered on the home page

import React from 'react';
import './Cards.css';
import CardItem from './CardItem';


function Cards() {
  return (
    <div className='home-cards'>
     
      <div className='home-cards-container'>
      
            <CardItem
              src='images/vegetables.jpg'
              text='Search for a Recipe and Instructions'
              label='Search'
              path='/search'
            />
            <CardItem
              src='images/yt-screenshot.jpg'
              text='Watch Cooking on YouTube'
              label='Watch'
              path='/watch'
            />

      </div>
    </div>
  );
}

export default Cards;
