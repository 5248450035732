//Page display for SIte Description
import React from 'react';
import SiteInfo from '../components/descripSection';
import '../App.css';
import './pages.css';



export default function Description() {
  return(
    <div className="search-page">
     <img className="search-bgimg" src="images/blueberries.jpg"/>
     <SiteInfo/>
    </div>
   
  
  )
}