//card item for home page 
//card Layout for the two Image links
//This is called in cards.jsx

import React from 'react';
import { Link } from 'react-router-dom';
// props passed in at cards.jsx --src, text, label, path
function CardItem(props) {
  return (
    <>
      <div className='card-item'>
        <Link className='card-item-link' to={props.path}>
          <div className='card-item-container'> 
          
            <img
              className='card-item-img'
              alt='cooking'
              src={props.src}
            />
            <div className='card-item-label'>{props.label}</div>
          </div>
          <div className='card-item-info'>
            <h5 className='card-item-text'>{props.text}</h5>
          </div>
        </Link>
      </div>
    </>
  );
}

export default CardItem;