//App.js sets page and renders navigation menu

import React, { useState } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Watch from './pages/Watch';
import Search from './pages/Search';
import Description from './pages/Description';

//pass prop to nav bar with current page
function App() {
  const [currentPage, setCurrentPage] = useState('Home');
  return (
    <>
      <Router>
        <Navbar currentPage={currentPage}/>
        <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/watch" element={<Watch/>}/>
        <Route exact path="/search" element={<Search/>}/>
        <Route exact path="/description" element={<Description/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;