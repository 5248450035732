//functionality for fetching and displaying Recipies from SPoontacular

import React from 'react';
import Checkbox from './checkBox'; // Import the Checkbox component
import '../pages/pages.css';
//set initial states
class RecipeFetch extends React.Component {
  state = {
    recipes: [],
    searchInput: '',
    vegetarian: false,
    glutenFree: false,
  };

  // 2 async functions in fetchRecupes
  //receipes recipe info based on search text
  //then uses the recipe id's to get more info, specifically the sourceURL
  fetchRecipes = async () => {
    //const apiKey = 'df594a3d799744e598850199aea9c372';// this one
    const apiKey ='263fed5795fc416f8a4b239a9554f761'//back up jl
    //const apiKey ='60ee98adaae64b0f82415f2e40eba6f9' //back up gu

    const { searchInput, vegetarian, glutenFree} = this.state;
    const url = `https://api.spoonacular.com/recipes/complexSearch?query=${searchInput}&diet=${vegetarian ? 'vegetarian' : ''}&intolerances=${glutenFree ? 'gluten' : ''}&number=6&apiKey=${apiKey}`;
    // gets data from first fetch then uses it for the second one 
    try {
      const response = await fetch(url);
      const data = await response.json();
      const recipeIds = data.results.map((recipe) => recipe.id);
      const recipeDetails = await Promise.all(
        recipeIds.map(async (id) => {
          const detailsResponse = await fetch(`https://api.spoonacular.com/recipes/${id}/information?includeNutrition=false&apiKey=${apiKey}`);
          const detailsData = await detailsResponse.json();
          return detailsData;
        })
      );
      const recipesWithDetails = data.results.map((recipe, index) => {
        return {
          ...recipe,
          sourceUrl: recipeDetails[index].sourceUrl
        };
      });
      this.setState({ recipes: recipesWithDetails });
    } catch (error) {
      console.error(error);
    }
  };
  //calls the fetchRecipes when component mounts
  componentDidMount() {
    this.fetchRecipes();
  }
  //when search form submitted
  //calls fetchRecipes and clears inout
  handleSearch = (event) => {
    event.preventDefault();
    this.fetchRecipes();
    this.setState({ searchInput: '' });
  };
  // called when input changes, changes value of searchInput
  handleInputChange = (event) => {
    this.setState({ searchInput: event.target.value });
  };

  //checkbox changes
  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  //renders page
  render() {
    const { recipes, searchInput, vegetarian, glutenFree} = this.state;

    return (
      <>
        <div className="recipe-page">
        <div className="search-info-text">Enter ingredients, style, cuisine to search. Separate by comma.</div>
          <div className="recipe-search-form">
            
            <form className="recipe-search-form" onSubmit={this.handleSearch}>
              <input className="search-text-box" type="text" value={searchInput} onChange={this.handleInputChange} />
              <Checkbox
                className="checkbox"
                label="Vegetarian"
                name="vegetarian"
                checked={vegetarian}
                onChange={this.handleCheckboxChange}
              />
              <Checkbox
                className="checkbox"
                label="Gluten-free"
                name="glutenFree"
                checked={glutenFree}
                onChange={this.handleCheckboxChange}
              />
              <button className="recipe-btn" type="submit">Search</button>
            </form>
          </div>
  
          <div className="recipes">
            {recipes.map((recipe) => (
              <div className="recipe-container" key={recipe.id}>
                <h2 className="recipe-title">{recipe.title}</h2>
                <div className="recipe-img-deets">
                  <div className="recipe-img">
                    <img src={recipe.image} alt={recipe.title} />
                  </div>
                  <div className="recipe-deets">
                    <div className="recipe-instructions">
                      <a className="rec-link" href={recipe.sourceUrl} target="_blank" alt={recipe.title}>View Recipe</a>
                    </div>
                  </div>
                </div>
              </div>
          
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default RecipeFetch;