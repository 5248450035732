//check box template for recipe search
import React from 'react';
import '../pages/pages.css'

class Checkbox extends React.Component {
  handleCheckboxClick = () => {
    const { name, checked, onChange } = this.props;
    onChange({ target: { name, checked: !checked } });
  };

  render() {
    const { label, checked } = this.props;

    return (
      <label onClick={this.handleCheckboxClick}>
        <input type="checkbox" checked={checked} readOnly />
        {label}
      </label>
    );
  }
}

export default Checkbox;
