//this component contains the fetch, search and display functions for the Watch Page
//With a search text from the user, a call is made to the Youtube API and the list of videos os recieved and displayed
//single fetch

import React, { useState , useEffect } from 'react';
import '../pages/pages.css';

//functional component
function WatchYouTube() {
 //state variables declared with useState hook
  const [searchResult, setSearchResult] = useState('');
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //useEffect hook calls the handleSearch function when component mounts 
  useEffect(() => {
    handleSearch();
  }, []);

 //handle search functions gets search input for fetch
 //default settings are given so videos are on page
 //the single fetch is performed

  const handleSearch = () => {
    setIsLoading(true);
    const searchInput = searchResult || 'Italian, garlic, broccoli'; // Default search term

    const url = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=6&order=rating&q=cooking with ${searchInput}&key=AIzaSyDcmJEc3tDqUMGfIIVgxqyxDdA-VrSOOEo`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setVideos(data.items || []);//video state updates with items
      })
      .catch((error) => {
        console.error('Error fetching videos:', error);
        setVideos([]);
      })
      .finally(() => {
        setIsLoading(false);
        setSearchResult(''); // Clear the search input
        
      });
    
  };
  //component is rendered 
  return (
    <div className="youtube-container">
      <div className="ytsearch-info-text">Enter ingredients, style, cuisine to search. Separate by comma.</div>
      <div className="search-container">
        <input
          className="search-text-box"
          id="search-input"
          type="text"
          value={searchResult}
          onChange={(e) => setSearchResult(e.target.value)}//react event triggered with change of search input
          placeholder="Enter your search"
        />
        <button className="yt-btn" onClick={handleSearch}>Search</button>
      </div>

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        videos.length > 0 && (
          <div className="videos">
            {videos.map((video) => (
              <div key={video.id.videoId}>
               {/*} <h3>{video.snippet.title}</h3>*/}
                <div className="video-container">
                  <iframe
                    title={video.snippet.title}
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${video.id.videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
}

export default WatchYouTube;



