//Functional Code for the Navigation
//called in App.js

import React, { useState, useEffect } from 'react';
import { Link,  useLocation } from 'react-router-dom';
//import Auth from './auth'
import './Navbar.css';
import '../App.css';

//called in App.js
//NavBar component recives the currentPage Prop to display Header Text
//state variables click and headerTitle defined using useState Hook
function Navbar({ currentPage }) {
  //navigation menu clicks
  const [click, setClick] = useState(false);
  //sets Header Text
  const [headerTitle, setHeaderTitle] = useState('Welcome');
  //location assigned variable returned by useLocation hook - returns location object for current URL
  const location = useLocation();

  //function Handleclick for the navigation toggle opposite on click
  const handleClick = () => setClick(!click);

  //function to close the mobilenavigation toggle on click
  const closeMobileMenu = () => setClick(false);
  //updates current page for use in Header Text
  const setCurrentPage = (title) => {
    setHeaderTitle(title);
  };

  // set Header title to match page/path
  const updateHeaderTitle = () => {
    const path = location.pathname;//from object location pathname is direct to page
    let title = '';

    switch (path) {
      case '/':
        title = 'Welcome';
        break;
      case '/watch':
        title = 'Watch';
        break;
      case '/search':
        title = 'Search';
        break;
      case '/description':
        title = 'Site Info';
        break;
      default:
        title = 'Welcome';
    }

    setHeaderTitle(title);
  };

  // Update the header title on initial page load
  // when page loads
  useEffect(() => {
    updateHeaderTitle();
  }, []);

  // Update the header title whenever the location changes
  useEffect(() => {
    updateHeaderTitle();
  }, [location]);

  {/* Nav bar rendering with hamburger menu click options for smaller devices */}
  return (
    <>
      <nav className='navbar'>
         <div className="welcome"><h3>{headerTitle}</h3></div>
        <div className='navbar-container'>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/search'
                className='nav-links'
                onClick={closeMobileMenu}
              >
               Search
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/watch'
                className='nav-links'
                onClick={closeMobileMenu}
              >
               Watch
              </Link>
            </li>
            <li>
              <Link
                to='/description'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Site Info
              </Link>
              </li>
              <li className="jt">
                <a
                  href="https://jtwebdev.com.au"
                  className="nav-links"
                >
                  JT Web Dev
                </a>
              </li>
          </ul>
         
        </div>
      </nav>
    </>
  );
}

export default Navbar;
