//Page display for Search
import React, { useState, useEffect } from 'react';
import '../App.css';
import './pages.css'
import RecipeFetch from '../components/recipeFetch';


export default function Search({}) {

  return  (

    <div className="search-page">
      <img className="search-bgimg" src="images/fresh-board.jpg"/>
      <RecipeFetch/>
    </div>
  )
  
}
