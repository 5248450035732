//Home Page Display

import React, { useState, useEffect } from 'react';
import '../App.css';
import HomeSection from '../components/HomeSection';


function Home({}) {
 
  return (
    <>
      <HomeSection />
    </>
  );
}

export default Home;