//This is the content for the site description page.

import React from 'react';
import '../pages/pages.css';
import { Link } from 'react-router-dom';

function SiteInfo() {
  return (
 
      <div className="description-page">
        <div className="description-info">
        <h1 className='description-head'>Ingredients to Meals Site Description</h1>
          <div className="text1">
            Welcome to Ingredients to Meals.<br/>
            This site has two search pages to find Recipies.<br/>
            The Search Page lets you search Recipes and follow a link to view.<br/>
            The Watch Page lets you search Recipes videos and view.<br/>
            <br/>
            Search a single ingredient, or multiple seperating with a comma. Some options to search by:
            <ul>
              <li>Ingredients</li>
              <li>Diet</li>
              <li>Cuisine</li>
              <li>and more</li>
            </ul>
            <div className="pics"><img className="search-img" src="images/search.jpg"/><img className="search-img" src="images/yt.jpg"/></div>
            
          </div>
        
      </div>
      </div>

  );
}

export default SiteInfo;