//Home Page Layout Displays the Cards and background video and is called in Home.jsx

import React from 'react';
import '../App.css';
import './HomeSection.css';
import Cards from './Cards';

function HomeSection() {
  return (
    <div className='home-container'>
      <video src='/videos/barley-grass.mp4' autoPlay loop muted />
      <h1>Ingredients to Meals</h1>
      <p>Let's Make Something</p>
        <Cards/>
    </div>
  );
}

export default HomeSection;