//Page display for Watch

import React from 'react';
import '../App.css';
import WatchYouTube from '../components/youTubeFetch';

export default function Watch() {
  return ( 

    <div className="search-page">
      <img className="search-bgimg" src="images/fresh-board.jpg"/>
      <WatchYouTube/>
    </div>
  )

}
